import { useFreshToken } from 'modules/auth/hooks/useFreshToken'
import { useMutation, UseMutationOptions } from 'react-query'
import { MutationFunction } from '../types/queries'

export const useMutationWithAuth = <
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown
>(
  mutationFn: MutationFunction<TData, TVariables>,
  mutationOptions?: UseMutationOptions<TData, TError, TVariables, TContext>
) => {
  const getFreshToken = useFreshToken()

  return useMutation<TData, TError, TVariables, TContext>(async (variables) => {
    const accessToken = await getFreshToken()

    if (!accessToken) {
      throw new Error(`useMutationWithAuth: Missing Access Token`)
    }

    return mutationFn(variables, accessToken)
  }, mutationOptions)
}
