import ExtensibleBar from './components/ExtensibleBar'
import PictoBar from './components/PictoBar'
import { useSidebarContextState } from 'modules/common/contexts/SidebarContext/SidebarContext'

export const Sidebar = () => {
  const {
    menus,
    isSidebarOpen,
    selectedMenuId,
    selectedSubMenuId,
  } = useSidebarContextState()

  const selectedMenu = menus.find(({ id }) => id === selectedMenuId)

  return (
    <div className="sticky top-14 z-10 flex h-dashboardViewport flex-row">
      <div className="z-20 flex">
        <PictoBar
          menus={menus}
          selectedMenuId={selectedMenuId}
          isSidebarOpen={isSidebarOpen}
        />
      </div>
      <div className="z-10 flex">
        <ExtensibleBar
          selectedMenu={selectedMenu}
          isSidebarOpen={isSidebarOpen}
          selectedSubMenuId={selectedSubMenuId}
        />
      </div>
    </div>
  )
}
