import { Button } from 'modules/common/components/_ui/Button/Button'
import { useHideModalContext } from 'modules/common/contexts/HideModalContext'
import { useState } from 'react'
import { UseFormReturn } from 'react-hook-form'

export interface FormModalFooterProps<T> {
  onCancel?: () => unknown
  cancelButtonText?: string
  submitButtonText?: string
  submitButtonDisabled?: boolean
  buttonsAlignment?: 'center' | 'stretch'
  form: UseFormReturn<T>
  onError?: () => unknown
  hiddenOnError?: boolean
  onSubmit?: (data: T) => unknown
}

export const FormModalFooter = <T,>({
  onCancel,
  cancelButtonText,
  submitButtonText,
  submitButtonDisabled,
  buttonsAlignment = 'stretch',
  form,
  onSubmit,
  onError,
  hiddenOnError = false,
}: FormModalFooterProps<T>) => {
  const [isLoading, setIsLoading] = useState(false)
  const hideModal = useHideModalContext()

  const buttonClassName =
    buttonsAlignment === 'stretch'
      ? 'flex-auto min-w-full sm:min-w-0 sm:px-10'
      : ''

  const submitForm = async (data: any) => {
    setIsLoading(true)
    try {
      onSubmit && (await onSubmit(data))
      hideModal()
    } catch {
      onError && (await onError())
      hiddenOnError && hideModal()
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      {cancelButtonText && (
        <Button
          variant="outline"
          color="active"
          type="button"
          onClick={async () => {
            if (onCancel) {
              await onCancel()
            }
            hideModal()
          }}
          data-testid="form-modal-cancel-button"
          className={buttonClassName}
        >
          {cancelButtonText}
        </Button>
      )}
      {submitButtonText && (
        <Button
          disabled={!form.formState.isValid || submitButtonDisabled}
          variant="contained"
          color="active"
          type="button"
          className={buttonClassName}
          isLoading={isLoading}
          data-testid="form-modal-submit-button"
          onClick={form.handleSubmit(submitForm)}
        >
          {submitButtonText}
        </Button>
      )}
    </>
  )
}
