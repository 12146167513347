import clsx from 'clsx'
import { motion, Variants } from 'framer-motion'
import { Menu } from 'modules/common/types/sidebar'
import React, { useState } from 'react'
import MenuToggle from './MenuToggle'
import SubMenus from './SubMenus'

const extensibleBarVariants: Variants = {
  closed: {
    width: '4rem',
  },
  opened: {
    width: '16rem',
  },
}

interface Props {
  isSidebarOpen: boolean
  selectedSubMenuId?: string
  selectedMenu?: Menu
}

const ExtensibleBar: React.FC<Props> = ({
  isSidebarOpen,
  selectedSubMenuId,
  selectedMenu,
}) => {
  const [isFullyOpen, setIsFullyOpen] = useState(isSidebarOpen)

  return (
    <motion.div
      initial={false}
      animate={isSidebarOpen ? 'opened' : 'closed'}
      variants={extensibleBarVariants}
      onAnimationComplete={() => {
        setIsFullyOpen(isSidebarOpen)
      }}
      transition={{
        ease: 'backIn',
      }}
      className={clsx(
        'flex-col bg-info-90 p-4',
        selectedSubMenuId ? 'flex' : 'hidden'
      )}
    >
      <MenuToggle isFullyOpen={isFullyOpen} />
      <div className="pl-6">
        <SubMenus
          isFullyOpen={isFullyOpen}
          selectedMenu={selectedMenu}
          selectedSubMenuId={selectedSubMenuId}
        />
      </div>
    </motion.div>
  )
}

export default ExtensibleBar
