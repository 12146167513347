import { useSidebarActionsContext } from 'modules/common/contexts/SidebarContext/SidebarActionsContext'
import { useTranslation } from 'modules/common/hooks/useTranslation'
import useSupportModal from 'modules/common/modals/useSupportModal/useSupportModal'
import { Menu } from 'modules/common/types/sidebar'
import { externalLinks } from '_constants'
import { Link } from '../../Link/Link'
import { IconConsultFaqs, IconShieldedDocument } from '../../_icons/icons'
import { Button } from '../../_ui/Button/Button'
import { PictoBarIcon } from './PictoBarIcon'

interface Props {
  menus: Menu[]
  isSidebarOpen: boolean
  selectedMenuId?: string
}

const PictoBar: React.FC<Props> = ({
  menus,
  isSidebarOpen,
  selectedMenuId,
}) => {
  const { openSidebar } = useSidebarActionsContext()

  const { showModal: showSupportModal } = useSupportModal()

  const { t } = useTranslation('common-components')

  const toggleMenu = async () => {
    if (!isSidebarOpen) {
      openSidebar()
    }
  }

  return (
    <div className="flex flex-col justify-between bg-primary-600 text-info-400">
      <div className="flex flex-col">
        {menus.map((menu) => (
          <Link href={menu.link} key={menu.id} title={menu.title}>
            <Button className="flex" onClick={toggleMenu} variant="none">
              <PictoBarIcon
                icon={menu.icon}
                id={menu.id}
                isSelected={selectedMenuId === menu.id}
                title={menu.title}
              />
            </Button>
          </Link>
        ))}
      </div>
      <div className="flex flex-col">
        <Button variant="none" onClick={showSupportModal}>
          <PictoBarIcon
            icon={<IconConsultFaqs />}
            id="HELP_CENTER"
            title={t('sidebar.menuLabels.HELP_CENTER')}
          />
        </Button>
        <Link href={externalLinks.legalLink} className="flex">
          <PictoBarIcon
            icon={<IconShieldedDocument />}
            id="LEGAL_LINKS"
            title={t('sidebar.menuLabels.LEGAL_LINKS')}
          />
        </Link>
      </div>
    </div>
  )
}

export default PictoBar
