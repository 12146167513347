import clsx from 'clsx'
import ReactTooltip from 'react-tooltip'
import styles from './PictoBar.module.css'

interface Props {
  icon: React.ReactNode
  id: string
  isSelected?: boolean
  title: string
}

export const PictoBarIcon: React.FC<Props> = ({
  icon,
  id,
  isSelected,
  title,
}) => (
  <>
    <span
      data-tip
      data-for={id}
      className={clsx(
        'w-full cursor-pointer p-5 text-primary-100 hover:text-white',
        isSelected && 'bg-primary-500'
      )}
    >
      {icon}
    </span>
    <ReactTooltip
      id={id}
      place="right"
      type="light"
      effect="solid"
      className={styles.opaque}
    >
      <p className="text-base font-bold leading-5 tracking-wide text-info-600">
        {title}
      </p>
    </ReactTooltip>
  </>
)
