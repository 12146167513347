import { motion } from 'framer-motion'
import { useTranslation } from 'modules/common/hooks/useTranslation'
import { Menu, SubMenuStatus } from 'modules/common/types/sidebar'
import React from 'react'
import { Link } from '../../Link/Link'
import {
  subMenuVariants,
  useSubMenusAnimation,
} from '../hooks/useSubMenusAnimation'

interface Props {
  isFullyOpen: boolean
  selectedMenu?: Menu
  selectedSubMenuId?: string
}

const SubMenus = ({ isFullyOpen, selectedMenu, selectedSubMenuId }: Props) => {
  const { t } = useTranslation(['common-components'])

  const controls = useSubMenusAnimation()

  if (!selectedMenu?.subMenus || selectedMenu.subMenus.length === 0) {
    return null
  }

  return (
    <ul>
      {selectedMenu?.subMenus.map((subMenu) => (
        <motion.li
          key={subMenu.link}
          animate={controls}
          style={isFullyOpen ? undefined : { x: subMenuVariants.closed.x }}
          className={
            subMenu.status === SubMenuStatus.ACTIVE
              ? 'cursor-pointer'
              : 'pointer-events-none'
          }
        >
          <Link href={subMenu.link}>
            <div className="flex flex-col items-start p-5">
              <h3
                className={`text-sm font-bold tracking-wide ${
                  selectedSubMenuId === subMenu.id
                    ? 'text-info-600'
                    : 'text-info hover:text-info-800'
                }`}
              >
                {t(`sidebar.subMenuLabels.${subMenu.id}`)}
              </h3>
            </div>
          </Link>
        </motion.li>
      ))}
    </ul>
  )
}

export default SubMenus
