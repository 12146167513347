import clsx from 'clsx'
import { Trans } from 'next-i18next'

interface Props {
  color?: 'black' | 'danger'
  text: string
}

export const HelperText: React.FC<Props> = ({ color = 'black', text }) => {
  return (
    <Trans
      className={clsx(
        'pt-1 pl-3 text-left text-xs',
        color === 'black' && 'text-info-600',
        color === 'danger' && 'text-danger'
      )}
      parent="p"
    >
      {text}
    </Trans>
  )
}
