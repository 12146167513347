import React from 'react'
import {
  ContentModalProps,
  useContentModal,
} from '../useContentModal/useContentModal'
import {
  FormModalFooter,
  FormModalFooterProps,
} from './components/FormModalFooter'
export interface FormModalProps<T>
  extends Omit<ContentModalProps, 'footer'>,
    FormModalFooterProps<T> {
  content: React.ReactNode
}

export const useFormModal = <T,>({
  form,
  content,
  onCancel,
  cancelButtonText,
  onSubmit,
  submitButtonText,
  submitButtonDisabled,
  buttonsAlignment,
  hiddenOnError,
  ...props
}: FormModalProps<T>) => {
  const {
    formState: { isValid, errors },
  } = form
  return useContentModal(
    {
      footer: (cancelButtonText || submitButtonText) && (
        <FormModalFooter
          onCancel={onCancel}
          cancelButtonText={cancelButtonText}
          onSubmit={onSubmit}
          submitButtonText={submitButtonText}
          submitButtonDisabled={submitButtonDisabled}
          form={form}
          hiddenOnError={hiddenOnError}
          buttonsAlignment={buttonsAlignment}
        />
      ),
      content: <form>{content}</form>,
      onCancel,
      ...props,
    },
    [form, isValid, errors]
  )
}
